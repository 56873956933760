import React from 'react';
import Footer from '../Footer';
import Header from '../Header';
import '../../styles/common.scss';
import './style.scss';

const Layout = ({ children }) => {
  return (
    <div id="wrapper">
      <Header />
      <main>{children}</main>
      <Footer />
    </div>
  );
};

export default Layout;
