import { Link } from 'gatsby';
import React, { useState } from 'react';
// import { useLocationUtils } from '../../hooks/useLocationUtils';
import Logo from '../../images/logo/DGMIT_LOGO.svg';
import IconClose from '../../images/icon/close.svg'
import IconMenu from '../../images/icon/menu.svg'
import './style.scss';

const Header = () => {
  // const { partiallyHasPathname, partiallyHasHash } = useLocationUtils();
  const [isMobileNavOpen, setIsMobileNavOpen] = useState(false);

  const mobileNavOpen = () => {
    setIsMobileNavOpen(true);
  }
  const mobileNavClose = () => {
    setIsMobileNavOpen(false);
  }

  return (
    <header id="header">
      <div className="container">
        <div className="row">
          <div className="header">
            {/* logo */}
            <h1 className="logo">
              <Link to="/">
                <img id="logo" src={Logo} alt="cvveLogo" />
              </Link>
            </h1>

            {/* nav */}
            <nav className="nav">
              <ul>
                <li>
                  <Link to="/#Mvv" 
                  // className={partiallyHasHash('#solution') ? 'active' : ''}
                  >
                    CORPORATE
                  </Link>
                </li>
                <li>
                  <Link to="/#Solution" 
                  // className={partiallyHasHash('#business') ? 'active' : ''}
                  >
                    SOLUTION
                  </Link>
                </li>
                <li>
                  <Link to="/#RecruitPdf" 
                  // className={partiallyHasPathname('/blog') ? 'active' : ''}
                  >
                    RECRUIT
                  </Link>
                </li>
                {/* <li>
                  <Link to="/#notice" 
                  // className={partiallyHasHash('#contact-us') ? 'active' : ''}
                  >
                    NOTICE
                  </Link>
                </li> */}
                {/* <li>
                  <Link to="/#contact-us" 
                  // className={partiallyHasHash('#contact-us') ? 'active' : ''}
                  >
                    CANTACT US
                  </Link>
                </li> */}
              </ul>
            </nav>

            <div className="mobile-menu">
              <img id="mobileMenu" src={IconMenu} alt="mobileMenu" onClick={()=>mobileNavOpen()} />
            </div>

            {/* mobile nav */}
            <nav className={isMobileNavOpen ? "mobile-nav open" : "mobile-nav"}>
              <div className="menu-close-btn">
                <img id="menuClose" src={IconClose} alt="menuClose" onClick={()=>mobileNavClose()} />
              </div>
              <ul>
                <li>
                  <Link to="/#Mvv" 
                  // className={partiallyHasHash('#solution') ? 'active' : ''}
                  >
                    CORPORATE
                  </Link>
                </li>
                <li>
                  <Link to="/#Solution" 
                  // className={partiallyHasHash('#business') ? 'active' : ''}
                  >
                    SOLUTION
                  </Link>
                </li>
                <li>
                  <Link to="/#RecruitPdf" 
                  // className={partiallyHasPathname('/blog') ? 'active' : ''}
                  >
                    Recruit
                  </Link>
                </li>
                {/* <li>
                  <Link to="/#notice" 
                  // className={partiallyHasHash('#contact-us') ? 'active' : ''}
                  >
                    NOTICE
                  </Link>
                </li> */}
                {/* <li>
                  <Link to="/#contact-us" 
                  // className={partiallyHasHash('#contact-us') ? 'active' : ''}
                  >
                    CANTACT US
                  </Link>
                </li> */}
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
