import React from 'react';
import Seo from '../components/common/Seo';
import PrivateSection from '../components/Policy/section/PrivateSection';
import Layout from '../layouts/Layout';

const Private = () => {
  return (
    <Layout>
      <PrivateSection />
    </Layout>
  );
};

export default Private;

export const Head = () => {
  return <Seo />;
};
